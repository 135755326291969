import { Button, Box, Toolbar, Stack } from "@mui/material";
import Logo from "./sks.png";
import { Typography } from "@mui/material";
import Modal from "./SmallModal";
import { useState } from "react";

function Header() {
  const [showModal, setShowModal] = useState();
  const [type, setType] = useState();

  const showMore = (id) => {
    setType(id);
    setShowModal(true);
  };

  const handleClose = (e) => {
    setShowModal(false);
  };

  return (
    <Toolbar
      sx={{
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        paddingBottom: "0.5rem",
        paddingTop: "0.5rem",
      }}
    >
      <a
        href="https://www.genealogia.fi/"
        alt="Linkki Suomen Sukututkimusseuran kotisivuille."
        title="Suomen Sukututkimusseura"
      >
        <Box
          component="img"
          sx={{
            height: 64,
          }}
          alt="Suomen sukututkimusseuran logo"
          src={Logo}
        />
      </a>
      <Typography className="header-title" variant="h4">
        Henkikirjahaku
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Button
          id="tietokanta"
          sx={{ color: "black" }}
          onClick={(e) => showMore(e.target.id)}
        >
          Tietokanta
        </Button>
        <Button
          id="hakuohjeet"
          sx={{ color: "black" }}
          onClick={(e) => showMore(e.target.id)}
        >
          Hakuohjeet
        </Button>
        <Button
          id="täydentäminen"
          sx={{ color: "black" }}
          onClick={(e) => showMore(e.target.id)}
        >
          Täydentäminen
        </Button>
      </Stack>
      <Modal showModal={showModal} handleClose={handleClose} type={type} />
    </Toolbar>
  );
}

export default Header;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = useState(false);
  const { type } = props;
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  useEffect(() => {
    if (props.showModal) {
      setOpen(true);
    }
  }, [setOpen, props.showModal]);

  const textProvider = (type) => {
    let text = "";
    if (type === "hakuohjeet") {
      text = (
        <p>
          Henkikirjahaun hakutoiminto on kirjainkokoriippumaton eli ei ole väliä
          onko hakutermi kirjoitettu isolla vai pienellä alkukirjaimella.
          Hakutermin alussa ja lopussa voi käyttää katkaisumerkkinä toimivaa
          asteriskia eli tähtimerkkiä (*), joka korvaa yhden tai useamman
          kirjaimen. Esimerkiksi haku termillä <i>Huovi*</i> palauttaa sukunimet{" "}
          <i>Huovila</i> ja <i>Huovinen</i>. Hakutermi <i>*nen</i> puolestaan
          palauttaa kaikki <i>nen</i>-päätteiset sukunimet. Katkaisumerkkiä ei
          voi käyttää vuosiluvuissa.<br></br>
          <br></br>
          Hakuja tehdessä on syytä huomioida, että henkikirjahakuun henkilöiden
          etunimet, patronyymit ja sukunimet on kirjattu lähdeuskollisesti eli
          ne on indeksoitu siinä muodossa kuin ne esiintyvät alkuperäisissä
          henkikirjoissa. Muut tiedot, kuten paikannimet, on normalisoitu
          nykykirjoitusasuun.<br></br>
          <br></br>Henkikirjahaussa hakeminen on mahdollista yhdellä tai
          useammalla hakuehdolla. Kaikki hakuehdot saa näkyviin klikkaamalla
          painiketta <i>Lisää hakuehtoja</i>. Painike <i>Tyhjennä</i> poistaa
          kaikki hakulomakkeeseen kirjatut ehdot uutta hakua varten.<br></br>
          <br></br> Hakutuloksissa yhden henkilön kaikki tiedot saa näkyviin
          kilkkamalla haluttua riviä. Tuloksia on mahdollista järjestää
          esimerkiksi aakkosjärjestykseen tai vuoden mukaan nousevaan tai
          laskevaan järjestykseen. Hakutuloksen linkki klikkaamalla pääsee
          katselemaan digitoitua henkikirjan sivua Kansallisarkiston Astia-
          palvelussa.
        </p>
      );
    } else if (type === "täydentäminen") {
      text = (
        <p>
          Henkikirjahaun täydentäminen perustuu vapaaehtoiseen indeksointiin,
          johon voi osallistua kuka tahansa. Henkikirjojen indeksoinnista
          järjestetään säännöllisesti infotilaisuuksia. Lisää tietoja
          Henkikirjahaun täydentämisestä löydät osoitteesta{" "}
          <a href="https://www.genealogia.fi/henkikirjahaku/">
            www.genealogia.fi/henkikirjahaku
          </a>
          .
        </p>
      );
    } else if (type === "tietokanta") {
      text = (
        <p>
          Henkikirjahaku on Suomen Sukututkimusseuran verkkopalvelu, jossa
          voidaan tehdä hakuja indeksoitujen henkikirjojen sisältöihin
          esimerkiksi henkilöiden ja paikkojen nimillä. Palvelulla pyritään
          edistämään ja helpottamaan henkikirjojen käyttöä sukututkimuksessa.
          Henkikirjoja voidaan käyttää myös korvaavana lähteenä tuhoutuneille
          kirkonkirjoille. Henkikirjahaun kehittämistä ovat tukeneet Karjalan
          Kulttuurirahasto, Waldemar von Frenckells stiftelse ja Föreningen
          Konstsamfundet.<br></br>
          <br></br>
          Henkikirjahaun versio 1.0 (29.3.2023) on toteutettu MVP-tuotteena
          (Minimum Viable Product) eli varhaisen käyttäjän vähimmäisvaatimukset
          täyttävänä verkkopalveluna ja sen kehittämistä jatketaan. Palautetta
          ja kehitysideoita voi lähettää Seuran verkkopalveluasiantuntija Mikko
          Kuitulalle osoitteella etunimi.sukunimi@genealogia.fi.
        </p>
      );
    }
    return text;
  };

  return (
    <div>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="small-modal">
            <Typography id="modal-modal-title" variant="h4" component="h2">
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </Typography>
            <hr></hr>
            <Typography sx={{ whiteSpace: "pre-line" }} variant="body1">
              {textProvider(type)}
            </Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
}

import "./App.css";
import { useState } from "react";
import { Container, Divider } from "@mui/material";
import Header from "./Header";
import Search from "./Search";
import Results from "./Results";
import { Typography } from "@mui/material";

function App() {
  const [search, setSearch] = useState(false);
  const [data, setData] = useState(false);
  const [query, setQuery] = useState(false);

  const handleSearch = async (params) => {
    setSearch(false); // re-renders datagrid to reset values between searches
    const baseUrl = `https://coral-app-s4vfg.ondigitalocean.app`;
    // const baseUrl = `http://localhost:8000`; // for local dev
    const qString = baseUrl + params + `&page=0&limit=10`;
    const response = await fetch(qString);
    setQuery(qString);
    setData(await response.json());
    setSearch(true);
  };

  const handleEmpty = () => {
    setSearch(false);
  };

  return (
    <Container
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Header />
      <Divider />
      <Search handleSearch={handleSearch} handleEmpty={handleEmpty} />
      {search && data && (
        <Results
          search={search}
          handleSearch={handleSearch}
          data={data}
          query={query}
        />
      )}
      <Typography
        align="center"
        sx={{
          paddingTop: "2rem",
          paddingBottom: "2rem",
          fontSize: "0.8em",
        }}
      >
        © Suomen Sukututkimusseura 2023
      </Typography>
    </Container>
  );
}

export default App;

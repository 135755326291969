import { useEffect } from "react";

const EnterKeyListener = ({ onEnter }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onEnter();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEnter]);

  return null;
};

export default EnterKeyListener;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./Modal.css";
import { useState, useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const info = props.row;

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
    setCopySuccess(false);
  };

  useEffect(() => {
    if (props.showModal) {
      setOpen(true);
    }
  }, [setOpen, props.showModal]);

  const copyToClipBoard = () => {
    setCopySuccess("Kopioitu leikepöydälle!");
    const text = `Vuosi: ${info.vuosi ?? ""}
      Paikkakunta: ${info.paikkakunta ?? ""}
      Kaupunginosa: ${info.kaupunginosa ?? ""}
      Kylä tai kortteli: ${info.kylä ?? ""}
      Tilannumero tai tonttinumero: ${info.numero ?? ""}
      Tila tai katu: ${info.tila ?? ""}
      Ammatti tai arvo: ${info["ammatti tai arvo"] ?? ""}
      Etunimi: ${info.etunimi ?? ""}
      Patronyymi: ${info.patronyymi ?? ""}
      Sukunimi: ${info.sukunimi ?? ""}
      Syntymävuosi: ${info.syntymävuosi ?? ""}
      Lisätiedot: ${info.lisätiedot ?? ""}
      Signum: ${info.signum ?? ""}
      Sivunumero: ${info.sivunumero ?? ""}
      Linkki: ${info.linkki ?? ""}`.replace(/  +/g, "");
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="ruudun peittävä modaali"
          aria-describedby="modaalissa on taulukko, joka sisältää valitun henkilön tiedot"
        >
          <Box sx={style}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography id="modal-modal-title" variant="h4" component="h2">
                {`${info.etunimi || ""} ${info.sukunimi || ""}`}
              </Typography>
              {!copySuccess && (
                <Button
                  id="copyButton"
                  variant="outlined"
                  style={{ color: "#424242", borderColor: "#424242" }}
                  onClick={async () => {
                    copyToClipBoard();
                  }}
                >
                  Kopioi
                  <ContentCopyIcon
                    style={{ paddingLeft: "0.5rem", fontSize: "large" }}
                  />
                </Button>
              )}
              {copySuccess && (
                <Typography variant="body2" style={{ alignSelf: "center" }}>
                  {copySuccess}
                </Typography>
              )}
            </Stack>
            <br></br>
            <TableContainer>
              <Table sx={{ width: "fit-content" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Kenttä</TableCell>
                    <TableCell>Arvo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={info.vuosi + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Vuosi
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.vuosi}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.paikkakunta + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Paikkakunta
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.paikkakunta}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.kaupunginosa + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Kaupunginosa
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.kaupunginosa}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info["kylä"] + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Kylä tai kortteli
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.kylä}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info["numero"] + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Tilannumero tai tonttinumero
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.numero}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info["tila"] + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Tila tai katu
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.tila}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={info["ammatti tai arvo"] + Math.random().toString()}
                  >
                    <TableCell component="th" scope="row">
                      Ammatti tai arvo
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info["ammatti tai arvo"]}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.etunimi + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Etunimi
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.etunimi}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.patronyymi + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Patronyymi
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.patronyymi}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.sukunimi + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Sukunimi
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.sukunimi}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.syntymävuosi + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Syntymävuosi
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.syntymävuosi}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.lisätiedot + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Lisätiedot
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.lisätiedot}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.signum + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Signum
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.signum}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.sivunumero + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Sivunumero
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {info.sivunumero}
                    </TableCell>
                  </TableRow>
                  <TableRow key={info.linkki + Math.random().toString()}>
                    <TableCell component="th" scope="row">
                      Linkki
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link href={info.linkki} target="_blank">
                        <OpenInNew />
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>
      )}
    </div>
  );
}

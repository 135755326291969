import {
  Button,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "./Search.css";
import EnterKeyListener from "./EnterKeyListener";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";

function Search(props) {
  const [birthYear, setBirthYear] = useState("");
  const [birthYearStart, setBirthYearStart] = useState("");
  const [birthYearEnd, setBirthYearEnd] = useState("");
  const [year, setYear] = useState("");
  const [yearStart, setYearStart] = useState("");
  const [yearEnd, setYearEnd] = useState("");
  const [county, setCounty] = useState("");
  const [townOrBlock, setTownOrBlock] = useState("");
  const [district, setDistrict] = useState("");
  const [estateOrStreet, setEstateOrStreet] = useState("");
  const [profession, setProfession] = useState("");
  const [patronym, setPatronym] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullSearch, setFullSearch] = useState();
  const [emptySearch, setEmptySearch] = useState(false);
  const [failedConnection, setFailedConnection] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useState(); // for future URL param sharing

  const handleEnter = () => {
    doSearch();
  };

  const toggleSearchType = () => {
    setFullSearch(!fullSearch);
  };

  const doSearch = async () => {
    setLoading(true);
    const parameters = fullSearch
      ? createSearchParams([
          year,
          yearStart,
          yearEnd,
          lastName,
          firstName,
          patronym,
          birthYear,
          birthYearStart,
          birthYearEnd,
          county,
          district,
          townOrBlock,
          estateOrStreet,
          profession,
        ])
      : createSearchParams([
          year,
          yearStart,
          yearEnd,
          lastName,
          firstName,
          patronym,
          birthYear,
          birthYearStart,
          birthYearEnd,
          county,
        ]);
    if (parameters) {
      setEmptySearch(false);
      setFailedConnection(false);
      try {
        await props.handleSearch(parameters);
        window.setTimeout(scrollDown, 100);
      } catch (error) {
        setFailedConnection(true);
      }
    } else {
      setEmptySearch(true);
    }
    setLoading(false);
  };

  const scrollDown = () => {
    const searchResultsHeader = document.getElementById("searchResultsHeader");
    searchResultsHeader.scrollIntoView({ behavior: "smooth" });
  };

  const createSearchParams = (params) => {
    const values = params.map((p) => p.trim().toLowerCase());
    let searchParams = "?";
    if (values[0]) {
      searchParams += `vuosi=${values[0]}&`;
    }
    if (values[1]) {
      searchParams += `yearStart=${values[1]}&`;
    }
    if (values[2]) {
      searchParams += `yearEnd=${values[2]}&`;
    }
    if (values[3]) {
      searchParams += `sukunimi=${values[3]}&`;
    }
    if (values[4]) {
      searchParams += `etunimi=${values[4]}&`;
    }
    if (values[5]) {
      searchParams += `patronyymi=${values[5]}&`;
    }
    if (values[6]) {
      searchParams += `syntymävuosi=${values[6]}&`;
    }
    if (values[7]) {
      searchParams += `birthYearStart=${values[7]}&`;
    }
    if (values[8]) {
      searchParams += `birthYearEnd=${values[8]}&`;
    }
    if (values[9]) {
      searchParams += `paikkakunta=${values[9]}&`;
    }
    if (values[10]) {
      searchParams += `kaupunginosa=${values[10]}&`;
    }
    if (values[11]) {
      searchParams += `kylä=${values[11]}&`;
    }
    if (values[12]) {
      searchParams += `tila=${values[12]}&`;
    }
    if (values[13]) {
      searchParams += `ammatti tai arvo=${values[13]}&`;
    }
    if (searchParams) {
      searchParams = searchParams.slice(0, -1);
    }
    setSearchParams(searchParams);
    return searchParams;
  };

  const startOver = () => {
    setBirthYear("");
    setBirthYearStart("");
    setBirthYearEnd("");
    setYear("");
    setYearStart("");
    setYearEnd("");
    setCounty("");
    setTownOrBlock("");
    setEstateOrStreet("");
    setProfession("");
    setPatronym("");
    setFirstName("");
    setLastName("");
    props.handleEmpty();
  };

  const handleYearChanges = (el) => {
    const { value } = el;
    if (el.id === "year") {
      setYear(value);
      if (value) {
        setYearStart("");
        setYearEnd("");
      }
    }
    if (el.id === "yearStart") {
      setYearStart(value);
      if (value) {
        setYear("");
      }
    }
    if (el.id === "yearEnd") {
      setYearEnd(value);
      if (value) {
        setYear("");
      }
    }
    if (el.id === "birthYear") {
      setBirthYear(value);
      if (value) {
        setBirthYearStart("");
        setBirthYearEnd("");
      }
    }
    if (el.id === "birthYearStart") {
      setBirthYearStart(value);
      if (value) {
        setBirthYear("");
      }
    }
    if (el.id === "birthYearEnd") {
      setBirthYearEnd(value);
      if (value) {
        setBirthYear("");
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <EnterKeyListener onEnter={handleEnter} />
      {emptySearch && (
        <Alert sx={{ margin: "1rem 0rem -1rem 0rem" }} severity="info">
          Syötä ainakin yksi hakutermi.
        </Alert>
      )}
      {failedConnection && !emptySearch && (
        <Alert sx={{ margin: "1rem 0rem -1rem 0rem" }} severity="error">
          Tietokantaan ei juuri nyt saatu yhteyttä. Yritä uudelleen hetken
          kuluttua.
        </Alert>
      )}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
      >
        <Typography variant="h4" className={"searchType "}>
          Haku
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
        >
          <TextField
            id="year"
            label="Vuosi"
            onChange={(e) => handleYearChanges(e.target)}
            style={{ width: "100%" }}
            value={year}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
          />
          <Box>
            <Typography>tai</Typography>
          </Box>
          <TextField
            id="yearStart"
            label="Aloitusvuosi"
            onChange={(e) => handleYearChanges(e.target)}
            style={{ width: "100%" }}
            value={yearStart}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
          />
          <Box>
            <Typography>—</Typography>
          </Box>
          <TextField
            id="yearEnd"
            label="Lopetusvuosi"
            onChange={(e) => handleYearChanges(e.target)}
            style={{ width: "100%" }}
            value={yearEnd}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Stack>

        <Stack spacing={2}>
          <TextField
            id="lastName"
            label="Sukunimi"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
          <TextField
            id="firstName"
            label="Etunimi"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <TextField
            id="patronym"
            label="Patronyymi"
            onChange={(e) => setPatronym(e.target.value)}
            value={patronym}
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
          >
            <TextField
              id="birthYear"
              label="Syntymävuosi"
              onChange={(e) => handleYearChanges(e.target)}
              style={{ width: "100%" }}
              value={birthYear}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
            <Box>
              <Typography>tai</Typography>
            </Box>
            <TextField
              id="birthYearStart"
              label="Aloitusvuosi"
              onChange={(e) => handleYearChanges(e.target)}
              style={{ width: "100%" }}
              value={birthYearStart}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
            <Box>
              <Typography>—</Typography>
            </Box>
            <TextField
              id="birthYearEnd"
              label="Lopetusvuosi"
              onChange={(e) => handleYearChanges(e.target)}
              style={{ width: "100%" }}
              value={birthYearEnd}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Stack>
          <TextField
            id="county"
            label="Paikkakunta"
            onChange={(e) => setCounty(e.target.value)}
            value={county}
          />
        </Stack>
        {fullSearch && (
          <Stack spacing={2}>
            <TextField
              id="district"
              label="Kaupunginosa"
              onChange={(e) => setDistrict(e.target.value)}
              value={district}
            />
            <TextField
              id="village"
              label="Kylä tai kortteli"
              onChange={(e) => setTownOrBlock(e.target.value)}
              value={townOrBlock}
            />
            <TextField
              id="estate"
              label="Tila tai katu"
              onChange={(e) => setEstateOrStreet(e.target.value)}
              value={estateOrStreet}
            />
            <TextField
              id="profession"
              label="Ammatti tai arvo"
              onChange={(e) => setProfession(e.target.value)}
              value={profession}
            />
          </Stack>
        )}
        <Stack
          direction="row"
          spacing={2}
          paddingTop={"0.5rem"}
          paddingBottom={"0.5rem"}
        >
          <Button
            id="searchButton"
            style={{ backgroundColor: "#424242" }}
            variant="contained"
            onClick={async () => {
              doSearch();
            }}
          >
            Hae
          </Button>
          <Button
            style={{ backgroundColor: "#424242" }}
            variant="contained"
            onClick={() => {
              startOver();
            }}
          >
            Tyhjennä
          </Button>
          <Button
            style={{ backgroundColor: "#424242" }}
            variant="contained"
            onClick={() => toggleSearchType()}
            onKeyDown={(e) => handleEnter(e)}
          >
            {fullSearch ? "Vähemmän hakuehtoja" : "Lisää hakuehtoja"}
          </Button>
        </Stack>
      </Stack>
      {loading && !emptySearch && <LinearProgress sx={{ marginTop: "1rem" }} />}
    </Container>
  );
}

export default Search;

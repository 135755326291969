import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Modal from "./Modal";
import "./Results.css";

function Results(props) {
  const [results, setResults] = useState(false);
  const [query, setQuery] = useState(false);
  const [showModal, setShowModal] = useState();
  const [row, setRow] = useState();
  const [loading, setLoading] = useState(false);
  const [failedConnection, setFailedConnection] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [rowCountState, setRowCountState] = useState(
    props.data.totalRowCount || 0
  );

  const [sortModel, setSortModel] = useState([
    {
      field: "syntymävuosi",
      sort: "desc",
    },
  ]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    vuosi: true,
    sukunimi: true,
    etunimi: true,
    patronyymi: true,
    syntymävuosi: true,
    paikkakunta: true,
    kylä: false,
    tila: false,
    kaupunginosa: false,
    numero: false,
    "ammatti tai arvo": false,
    signum: false,
    sivunumero: false,
    lisätiedot: false,
    linkki: true,
  });

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      props.data.totalRowCount !== undefined
        ? props.data.totalRowCount
        : prevRowCountState
    );
    setResults(props.data.persons);
    setQuery(props.query);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [props.data, props.query]);

  const changePage = async (paginationModel) => {
    setFailedConnection(false);
    const { page } = paginationModel;
    const { pageSize } = paginationModel;
    let newUrl = new URL(query);
    let params = new URLSearchParams(newUrl.search);
    params.set("page", page);
    params.set("limit", pageSize);
    newUrl.search = params;
    let newQuery = newUrl.toString();
    setLoading(true);
    try {
      const response = await fetch(newQuery);
      setPaginationModel(paginationModel);
      setQuery(newQuery);
      const { persons } = await response.json();
      setResults(persons);
    } catch (error) {
      setFailedConnection(true);
    }
    setLoading(false);
  };

  const sort = async (newSortModel) => {
    setFailedConnection(false);
    let newUrl = new URL(query);
    let params = new URLSearchParams(newUrl.search);
    let newQuery = "";
    if (newSortModel.length) {
      let field = newSortModel[0].field;
      let sort = newSortModel[0].sort;
      params.set("field", field);
      params.set("sort", sort);
      newUrl.search = params;
      newQuery = newUrl.toString();
      setSortModel(newSortModel);
    } else {
      params.set("field", null);
      params.set("sort", null);
      newUrl.search = params;
      newQuery = newUrl.toString();
      setSortModel([]);
    }
    setQuery(newQuery);
    setLoading(true);
    try {
      const response = await fetch(newQuery);
      const { persons } = await response.json();
      setResults(persons);
    } catch (error) {
      setFailedConnection(true);
    }
    setLoading(false);
  };

  const showMore = (e) => {
    setRow(e.row);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 480;

  const columns = !isMobile
    ? [
        {
          field: "vuosi",
          headerName: "Vuosi",
          sortable: false,
          flex: 1,
          isVisible: false,
        },
        { field: "sukunimi", headerName: "Sukunimi", flex: 1 },
        { field: "etunimi", headerName: "Etunimi", sortable: false, flex: 1 },
        {
          field: "patronyymi",
          headerName: "Patronyymi",
          sortable: false,
          flex: 1,
        },
        { field: "syntymävuosi", headerName: "Syntymävuosi", flex: 1 },
        {
          field: "paikkakunta",
          headerName: "Paikkakunta",
          sortable: false,
          flex: 1,
        },
        {
          field: "kylä",
          headerName: "Kylä tai kortteli",
          sortable: false,
          flex: 1,
        },
        {
          field: "tila",
          headerName: "Tila tai katu",
          sortable: false,
          flex: 1,
        },
        {
          field: "kaupunginosa",
          headerName: "Kaupunginosa",
          sortable: false,
          flex: 1,
        },
        {
          field: "numero",
          headerName: "Tilannumero tai tonttinumero",
          sortable: false,
          flex: 1,
        },
        {
          field: "ammatti tai arvo",
          headerName: "Ammatti tai arvo",
          sortable: false,
          flex: 1,
        },
        {
          field: "signum",
          headerName: "Signum",
          sortable: false,
          flex: 1,
        },
        {
          field: "sivunumero",
          headerName: "Sivunumero",
          sortable: false,
          flex: 1,
        },
        {
          field: "lisätiedot",
          headerName: "Lisätiedot",
          sortable: false,
          flex: 1,
        },
        {
          field: "linkki",
          headerName: "Linkki",
          width: 75,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => (
            <Link href={params.value} target="_blank">
              <OpenInNew />
            </Link>
          ),
        },
      ]
    : [
        { field: "sukunimi", headerName: "Sukunimi", flex: 1 },
        { field: "etunimi", headerName: "Etunimi", sortable: false, flex: 1 },
        { field: "syntymävuosi", headerName: "Syntymävuosi", flex: 1 },
      ];

  const localizedTextsMap = {
    columnMenuUnsort: "Palauta järjestys",
    columnMenuSortAsc: "Nouseva järjestys",
    columnMenuSortDesc: "Laskeva järjestys",
    columnMenuHideColumn: "Piilota kolumni",
    columnMenuManageColumns: "Muokkaa kolumneja",
    columnsPanelTextFieldLabel: "Etsi kolumni",
    columnsPanelTextFieldPlaceholder: "Kolumnin otsikko",
    columnsPanelShowAllButton: "Näytä kaikki",
    columnsPanelHideAllButton: "Piilota kaikki",
    noRowsLabel: "Ei hakutuloksia",
  };

  return (
    <div>
      <Typography
        style={{ paddingTop: "2rem" }}
        id="searchResultsHeader"
        variant="h4"
      >
        Hakutulokset
      </Typography>
      {failedConnection && (
        <Alert sx={{ marginTop: "1rem" }} severity="error">
          Tietokantaan ei juuri nyt saatu yhteyttä. Yritä uudelleen hetken
          kuluttua.
        </Alert>
      )}
      <Stack spacing={2}>
        <Modal showModal={showModal} handleClose={handleClose} row={row} />
        {results && (
          <DataGrid
            componentsProps={{
              pagination: {
                labelRowsPerPage: "Rivejä sivulla:",
              },
            }}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
            loading={loading}
            rowCount={rowCountState}
            rows={results}
            getRowId={(row) => row._id}
            columns={columns}
            disableColumnFilter={true}
            localeText={localizedTextsMap}
            hideFooterSelectedRowCount={true}
            autoHeight={true}
            paginationMode="server"
            pageSizeOptions={[10, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={(page) => changePage(page)}
            onRowClick={(e) => showMore(e)}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => sort(newSortModel)}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        )}
      </Stack>
    </div>
  );
}

export default Results;
